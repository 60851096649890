import styled from "styled-components";
import device from "src/constants/media";
import { typography, colors } from "src/theme";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 120px;
  margin-inline: auto;
  margin-top: 80px;
  max-width: 1096px;

  @media ${device.laptop} {
    margin-top: 160px;
  }
`;
export const FeatureBlock = styled.div<{ index: number }>`
  place-items: center;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr;
  grid-template-areas: "image" "text";

  @media ${device.laptop} {
    grid-template-columns: ${({ index }) => (index % 2 ? "2fr 1fr" : "1fr 2fr")};
    grid-template-areas: ${({ index }) => (index % 2 ? '"image text"' : '"text image"')};
  }
`;
export const Blob = styled.div`
  grid-area: image;
  display: flex;
  place-content: center;
  place-items: center;
  max-height: 420px;
  max-width: 100vw;
`;
export const ImageSection = styled.div`
  grid-area: image;
  display: flex;
  place-content: center;
  place-items: center;
  max-width: calc(100vw - 20px);
`;
export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  grid-area: text;
  place-content: center;
  padding-inline: 24px;
  max-width: 100vw;

  @media ${device.laptopS} {
    padding: 0;
  }
`;
