// libs
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import Link from "src/components/ui/Link";
// const
import { WHY_PETS_APP_BLOCK } from "src/constants/dynamic";
// hooks
import { useAppSelector } from "src/hooks";
import { amplitudeEvent, formatEventName } from "src/libs/amplitude";
// redux
import { dynamicStateFieldSelector } from "src/redux/slices";
import { colors } from "src/theme";
import { Action, Callout, LargeTitle, Title } from "src/theme/typography";
// types
import { IDynamicWhyPetsApp, IDynamicWhyPetsAppBlock } from "src/types/dynamic/clinics/whyPetsApp";
// theme
import { FeatureBlock, ImageSection, Section, TextSection, Blob } from "./UseCases.styles";

interface IUseCasesBlock extends IDynamicWhyPetsAppBlock {
  subtitle?: string;
}

const UseCases = () => {
  const data = useAppSelector(dynamicStateFieldSelector<IDynamicWhyPetsApp>(WHY_PETS_APP_BLOCK));
  const { pathname } = useRouter();

  const featuresData: Array<IUseCasesBlock> = [
    { ...data.blocks[0], subtitle: "Week 1" },
    { ...data.blocks[1], subtitle: "Week 4" },
    { ...data.blocks[2], subtitle: "Week 8-12" },
    ...data.blocks.slice(3),
  ];

  return (
    <Section>
      {featuresData.map((block, idx) => (
        <FeatureBlock key={block.id} index={idx}>
          <Blob>
            <img src={`/background/blob-${idx + 1}.svg`} />
          </Blob>
          <ImageSection>
            <Image src={block.image?.url || "/test"} width={480} height={480} objectFit="contain" />
          </ImageSection>
          <TextSection>
            {block.subtitle && <Title textColor={colors.orange}>{block.subtitle}</Title>}
            <LargeTitle as="h3" textColor={colors.white}>
              {block.title}
            </LargeTitle>
            <Callout textColor={colors.mainVeryLight}>{block.text}</Callout>
            {(block.buttons || []).map((button) => (
              <Link key={button.id} href={button.url || "/"}>
                <Action
                  textColor={colors.white}
                  style={{
                    textDecoration: "underline",
                    textDecorationThickness: 2,
                    textUnderlineOffset: 3,
                  }}
                  as="span"
                  onClick={() =>
                    amplitudeEvent(formatEventName(pathname, block.title, button.title))
                  }
                >
                  {button.title}
                </Action>
              </Link>
            ))}
          </TextSection>
        </FeatureBlock>
      ))}
    </Section>
  );
};

export default UseCases;
